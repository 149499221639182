import React from 'react';
import { IMarketplaceProduct } from '../../pages/MarketplaceMyProducts';
import LoadingComponent from '../common/LoadingComponent';
import { Link } from 'react-router-dom';

const tableHeader = [
  { name: 'Resim' },
  { name: 'Başlık' },
  { name: 'Satış Adedi' },
  { name: 'Ciro' },
  { name: 'Ort. Satış Fiyatı' },
  { name: 'Detay' },
];

interface Props {
  data: IMarketplaceProduct[];
  isLoading: boolean;
}
const MyProductsTable: React.FC<Props> = ({ data, isLoading }) => {
  return (
    <div className="hidden lg:block ">
      <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto scrollbar-hide">
        <div className="inline-block min-w-full py-2 align-middle px-4">
          <div className=" flex flex-col flex-shrink-0 bg-[#F9FAFB] rounded-xl mt-8">
            <div className="relative overflow-y-auto overflow-x-auto scrollbar-hide">
              <div className="inline-block min-w-full align-middle h-[100vh]">
                <table className="w-full">
                  <thead>
                    <tr>
                      {tableHeader.map((item, index) => {
                        return (
                          <th
                            key={index}
                            className="sticky top-0 z-10 text-center bg-headerPrimary py-3.5 pl-4 pr-3 text-sm font-semibold text-white first:rounded-tl-lg last:rounded-tr-lg first:rounded-bl-lg last:rounded-br-lg"
                            // scope="col"
                          >
                            {' '}
                            <p className="text-xs md:text-sm">{item.name}</p>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  {isLoading ? (
                    <div className="w-full flex justify-center">
                      <LoadingComponent />
                    </div>
                  ) : (
                    <tbody className="divide-y divide-gray-200 bg-[#F9FAFB]">
                      {data?.map((product: any, idx) => {
                        return (
                          <tr key={idx} className="text-[#374151]">
                            <td className="px-1 text-xs md:text-sm py-2">
                              <div className="flex justify-center">
                                <img
                                  src={product.images[0]}
                                  alt="product_etsy"
                                  className="w-[140px] aspect-square rounded-xl"
                                />
                              </div>
                            </td>
                            <td className="px-1 text-xs md:text-sm w-48">
                              <div className="flex justify-center">
                                <p>{product.title.substring(0, 80)}</p>
                              </div>
                            </td>
                            <td className="text-xs md:text-sm ">
                              <p className="text-center">
                                {product.order_count}
                              </p>
                            </td>
                            <td className="text-xs md:text-sm ">
                              <p className="text-center">
                                $
                                {product.averageProductSalesPrice
                                  ? Number(
                                      product.order_count *
                                        product.averageProductSalesPrice
                                    ).toFixed(2)
                                  : '-'}
                              </p>
                            </td>
                            <td className="text-xs md:text-sm ">
                              <p className="text-center">
                                ${' '}
                                {product.averageProductSalesPrice
                                  ? product.averageProductSalesPrice.toFixed(2)
                                  : '-'}
                              </p>
                            </td>
                            <td className="text-xs md:text-sm ">
                              <div className="flex justify-center">
                                <Link
                                  className="bg-[#0284C7] text-white font-medium rounded-lg px-4 py-2"
                                  to={`/seller-product-details/${product.product_id}`}
                                >
                                  Detay
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProductsTable;
