import { DollarSquare, ShoppingCart, WalletMinus } from 'iconsax-react';
import { Link } from 'react-router-dom';
import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import Pagination from '../common/Pagination';
// import {  SellerProductsTable } from './ProductsTable';

interface Props {
  isLoading: boolean;
  data: any;
}
const MobileProductCard: React.FC<Props> = ({ isLoading, data }) => {
  const { session } = useAuth();

  return (
    <div className="lg:hidden grid grid-cols-12 gap-4 mt-8">
      {data?.map((product: any) => {
        return (
          <div
            key={product.id}
            className="col-span-12 md:col-span-6 shadow-lg bg-[#f9fafb] rounded-[12px] flex flex-col items-center justify-center py-[32px] px-[16px] mb-[16px]"
            style={{ boxShadow: '0px 4px 12px rgba(17, 24, 39, 0.15)' }}
          >
            <img
              className="h-[150px] w-[150px] rounded-lg"
              src={`${product?.images[0]}`}
              alt="productImg"
            />
            <p className="pt-[16px] pb-[24px] text-[#374151] font-medium text-[14px]">
              {product.title}
            </p>
            <div className="flex flex-row items-center justify-between w-full text-[#374151] font-normal">
              <span className="text-[14px]">Ortalama Satış Fiyatı</span>
              <span className="text-[28px]">
                ${' '}
                {product.averageProductSalesPrice ? (
                  <span>{`${Number(product.averageProductSalesPrice).toFixed(
                    2
                  )}`}</span>
                ) : (
                  '-'
                )}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between w-full text-[#374151] font-normal text-[14px] pt-[26px] pb-[18px]">
              <div className="flex flex-row gap-1 items-center justify-start">
                <ShoppingCart color="#F97316" variant="Bold" />
                <span>{product.order_count}</span>
              </div>
              <div className="flex flex-row gap-1 items-center justify-start">
                <DollarSquare color="#10B981" variant="Bold" />
                <span>
                  ${' '}
                  {product.productRevenue ? (
                    <span>{`${Number(product.productRevenue).toFixed(
                      2
                    )}`}</span>
                  ) : (
                    '-'
                  )}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileProductCard;
