import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

interface YoutubePlayerProps {
  title: string;                 // "/video-title.mp4" şeklinde gelecek
  courseId: number;             // Ders id
  selectedContentId: any;       // Seçili içeriğin id'si
  update: (courseId: number, contentId: number) => void;  // Videonun bitiminde tetiklenecek fonksiyon
}

// VimeoData’ya benzer şekilde YouTube linklerini tutacağınız yeni bir videoData
// Bu sadece örnek amaçlı verilmiştir. Dilerseniz videolarınızın ID'lerini tutarak
// "https://www.youtube.com/watch?v=" + youtubeID şeklinde de kullanabilirsiniz.
const youtubeVideoData = [
  {
    course: '2-ucretsiz-etsy-egitimi',
    mainTitle: '1-genel',
    videoTitle: '2-ucretsiz-etsy-egitimi',
    embed: {
      src: 'https://youtu.be/Jf_AjSbD2NQ?si=4IHkwpQuXb_okWVF', // Örnek YouTube linki
      title: '2-ucretsiz-etsy-egitimi'
    }
  },
];

const YoutubePlayer: React.FC<YoutubePlayerProps> = ({
  title,
  update,
  courseId,
  selectedContentId,
}) => {
  const [videoSrc, setVideoSrc] = useState<string>('');

  // title ile /videoAdı.mp4 karşılaştırarak videoyu buluyoruz
  const handleTitleClick = () => {
    const foundVideo = youtubeVideoData[0]
    if (foundVideo) {
      setVideoSrc(foundVideo.embed.src);
    }
  };

  // title değeri değiştiğinde handleTitleClick çağrılır
  useEffect(() => {
    handleTitleClick();
  }, [title]);

  return (
    <div style={{ padding: '56.21% 0 0 0', position: 'relative' }}>
      <ReactPlayer
        url={videoSrc}
        playing={true}
        controls={true}
        width="100%"
        height="100%"
        onEnded={() => update(courseId, selectedContentId)}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
    </div>
  );
};

export default YoutubePlayer;