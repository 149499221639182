import {
  Box1,
  CloseSquare,
  EyeSlash,
  InfoCircle,
  Link2,
  TickCircle,
  Ticket,
  TruckFast,
  Wallet1,
  Copy,
  Truck,
} from 'iconsax-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import API_URL from '../../../config';
import { classNames } from '../../../utils/conditionalClasses';
import { formatDateTR } from '../../../utils/date';
import LoadingComponent from '../../common/LoadingComponent';
import { Tooltip } from 'react-tooltip';
import ReactCountryFlag from 'react-country-flag';
import MatchedProductModal from './MatchedProductModal';
import ImageDetailModal from './ImageDetailModal';
import WarningText from '../WarningText';

export type LabelKey =
  | 'waitingMatch'
  | 'waitingPayment'
  | 'producing'
  | 'ready'
  | 'waitingLabel'
  | 'shipped'
  | 'delivered';

export const labels = {
  waitingMatch: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#4F46E5] px-2 p-1 w-full">
      <Link2 size="18" color="#4F46E5" variant="Bold" />
      <p className="text-[#4F46E5] text-sm">Eşleşme Bekliyor</p>
    </div>
  ),

  waitingPayment: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#0D9488] px-2 p-1 w-full">
      <Wallet1 size="18" color="#0D9488" />
      <p className="text-[#0D9488] text-sm">Ödeme Bekliyor</p>
    </div>
  ),
  waitingProducing: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#EC4899] px-2 p-1 w-[200px]">
      <Box1 size="18" color="#EC4899" />
      <p className="text-[#EC4899] text-sm">Üretim Bekliyor</p>
    </div>
  ),
  producing: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#7C3AED] px-2 p-1 w-full">
      <Box1 size="18" color="#7C3AED" />
      <p className="text-[#7C3AED] text-sm">Üretiliyor</p>
    </div>
  ),
  // ready: (
  //   <div className="flex space-x-2 justify-center items-center rounded-full border border-[#06B6D4] px-2 p-1 w-full">
  //     <Box1 size="18" color="#06B6D4" />
  //     <p className="text-[#06B6D4] text-sm">Hazır</p>
  //   </div>
  // ),
  // waitingLabel: (
  //   <div className="flex space-x-2 justify-center items-center rounded-full border border-[#F59E0B] px-2 p-1 w-full">
  //     <TicketStar size="18" color="#F59E0B" />
  //     <p className="text-[#F59E0B] text-sm">Etiket Bekliyor</p>
  //   </div>
  // ),

  shipped: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#0EA5E9] px-2 p-1 w-full">
      <TruckFast size="18" color="#0EA5E9" />
      <p className="text-[#0EA5E9] text-sm">Kargoya Verildi</p>
    </div>
  ),
  inTransit: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#0EA5E9] px-2 p-1 w-full">
      <TruckFast size="18" color="#0EA5E9" />
      <p className="text-[#0EA5E9] text-sm">Kargo Yolda</p>
    </div>
  ),
  delivered: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#059669] px-2 p-1 w-full">
      <TickCircle size="18" color="#059669" />
      <p className="text-[#059669] text-sm">Teslim Edildi</p>
    </div>
  ),
  hidden: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#4F46E5] px-2 p-1 w-full">
      <EyeSlash size="18" color="#4F46E5" variant="Bold" />
      <p className="text-[#4F46E5] text-sm">Gizli</p>
    </div>
  ),
  cancelled: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#4F46E5] px-2 p-1 w-full">
      <CloseSquare size="18" color="red" variant="Bold" />
      <p className="text-red-500 text-sm">Gizli</p>
    </div>
  ),
};
const tableHeader = [
  { name: 'Seç' },

  { name: 'Sipariş' },
  { name: 'Fotoğraf' },
  { name: 'Ürün Açıklaması' },

  { name: 'Kargo Etiketi' },

  { name: 'Tasarım' },

  { name: 'Önemli Not' },
];
interface Props {
  isLoading: boolean;
  orders: any[];
  page: number;
  setSelectedOrders: React.Dispatch<React.SetStateAction<any>>;
  selectedOrders: any;
  ref: any;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getSupplierOrders: () => void;
}

const SupplierOrdersTable = React.forwardRef<HTMLTableElement, Props>(
  (
    {
      setSelectedOrders,
      page,
      selectedOrders,
      orders,
      isLoading,
      setIsModalOpen,
      getSupplierOrders,
    },
    ref
  ) => {
    const [selectedOrderItem, setSelectedOrderItem] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const toggleOrderSelection = (selectedItem: any) => {
      setSelectedOrders((prevSelectedOrders: any) => {
        const isAlreadySelected = prevSelectedOrders.some(
          (orderItem: any) =>
            orderItem.seller_order_items[0].id ===
            selectedItem.seller_order_items[0].id
        );
        if (isAlreadySelected) {
          return prevSelectedOrders.filter(
            (orderItem: any) =>
              orderItem.seller_order_items[0].id !==
              selectedItem.seller_order_items[0].id
          );
        } else {
          return [...prevSelectedOrders, selectedItem];
        }
      });
    };
    const calculateDate = (shipByDate: string) => {
      const shipDate = new Date(shipByDate);
      const today = new Date();

      if (
        today.getDate() === shipDate.getDate() &&
        today.getMonth() === shipDate.getMonth() &&
        today.getFullYear() === shipDate.getFullYear()
      ) {
        return 'today';
      } else if (today > shipDate) {
        return 'exceed';
      } else if (
        today.getDate() === shipDate.getDate() - 1 &&
        today.getMonth() === shipDate.getMonth() &&
        today.getFullYear() === shipDate.getFullYear()
      ) {
        return 'beforeOne';
      } else {
        return '';
      }
    };
    const toggleAllOrdersSelection = () => {
      if (selectedOrders.length === orders.length) {
        setSelectedOrders([]);
      } else {
        setSelectedOrders(orders);
      }
    };

    const downloadLabel = async (url: string) => {
      url = url.includes('https') ? url : `${API_URL}${url}`;
      console.log(url);
      const response = await fetch(url);
      console.log(response);
      const blob = await response.blob();
      const filename = 'label.pdf';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    };
    const renderImages = (item: any) => {
      if (item.buyedMockups && item.buyedMockups.length > 0) {
        return item.buyedMockups.map((mockup: any, index: number) => {
          return (
            <div className="flex flex-col items-center justify-center w-full h-[200px]">
              <img
                onLoad={() => {}}
                className="w-24 aspect-square object-contain object-center rounded-lg"
                src={`${API_URL}${mockup.path}`}
                alt="Mockup"
              />
              {item?.buyedDesigns && (
                <a
                  download
                  target="_blank"
                  className="bg-[#FB923C] rounded-full w-1/2 py-1 px-2 block text-center text-xs text-white mt-1"
                  href={`${API_URL}${item?.buyedDesigns[index]?.path}`}
                  rel="noreferrer"
                >
                  İndir
                </a>
              )}
            </div>
          );
        });
      } else {
        return null;
      }

      // else {
      //   if (item.mockupFiles && item.mockupFiles.length > 0) {
      //     return item.mockupFiles.map((mockup: any, index: number) => {
      //       return (
      //         <div className="flex flex-col items-center justify-center w-full h-[200px]">
      //           <img
      //             onLoad={() => { }}
      //             className="w-24 aspect-square object-contain object-center rounded-lg"
      //             src={`${API_URL}${mockup}`}
      //             alt="Design"
      //           />
      //           <a
      //             download
      //             target="_blank"
      //             className="bg-[#FB923C] rounded-full w-1/2 py-1 px-2 block text-center text-xs text-white mt-1"
      //             href={`${API_URL}${item.designFiles[index]}`}
      //             rel="noreferrer"
      //           >
      //             İndir
      //           </a>
      //         </div>
      //       );
      //     });
      //   } else {
      //     return null;
      //   }
      // }
    };
    // const getShipstationLabel = async (orderId: number) => {
    //   try {
    //     await httpClient.get(
    //       `/api/v1/marketplace/shipstation-label?platformOrderId=${orderId}`
    //     );
    //     // change modal row color to green and say label created
    //   } catch (error: any) {
    //     // change modal row color to red and say label not created
    //   } finally {
    //   }
    // };

    // const getShipentegraLabel = async (orderId: number) => {
    //   try {
    //     await httpClient.get(
    //       `/api/v1/marketplace/shipentegra-label?platformOrderId=${orderId}`
    //     );
    //     // change modal row color to green and say label created
    //   } catch (error: any) {
    //     // change modal row color to red and say label not created
    //   } finally {
    //   }
    // };
    const decideTracking = (serviceCode: string, trackingNumber: string) => {
      if (serviceCode.includes('shipentegra')) {
        return `https://track.shipentegra.com/track?showLastMileCarrier=true&trackno=${trackingNumber}`;
      } else if (serviceCode.includes('usps')) {
        return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${trackingNumber}%2C&tABt=true`;
      } else if (serviceCode.includes('ups')) {
        return `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`;
      }
    };

    return (
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto scroll">
          <div className="inline-block min-w-full py-2 align-middle  sm:px-6 lg:px-8">
            <div className=" flex flex-col flex-shrink-0 bg-[#F9FAFB] rounded-xl mt-8 ">
              <div className="relative overflow-y-auto scroll">
                <div className=" inline-block min-w-full align-middle h-[65vh]">
                  <table className="min-w-[1280px] w-full" ref={ref}>
                    <thead>
                      <tr>
                        {tableHeader.map((item, index) => {
                          return (
                            <th
                              key={index}
                              className="sticky top-0 z-10 bg-headerPrimary py-3.5 pl-4 pr-3 text-sm text-center font-semibold text-white first:rounded-tl-lg last:rounded-tr-lg first:rounded-bl-lg last:rounded-br-lg"
                              // scope="col"
                            >
                              <div className="flex justify-center items-center space-x-1">
                                {index === 0 && (
                                  <input
                                    type="checkbox"
                                    className="rounded-sm border-blue-500"
                                    checked={
                                      selectedOrders.length === orders.length
                                    }
                                    onChange={toggleAllOrdersSelection}
                                  />
                                )}
                                <p className="text-xs text-center md:text-sm">
                                  {item.name}
                                </p>
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>

                    {isLoading ? (
                      <div className="w-full flex justify-center">
                        <LoadingComponent />
                      </div>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-[#F9FAFB]">
                        {orders?.length > 0 ? (
                          orders?.map((order: any, idx) => {
                            return (
                              <tr
                                key={idx}
                                className={classNames(
                                  idx === 0
                                    ? 'border-none'
                                    : 'border-b-[#D1D5DB]',
                                  ' rounded-xl'
                                )}
                              >
                                <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0">
                                  <div className="flex justify-center space-x-2">
                                    {
                                      <input
                                        type="checkbox"
                                        className="rounded-sm border-blue-500"
                                        checked={selectedOrders?.some(
                                          (orderItem: any) =>
                                            orderItem.seller_order_items[0]
                                              .sellerOrderId ===
                                            order.seller_order_items[0]
                                              .sellerOrderId
                                        )}
                                        onChange={() =>
                                          toggleOrderSelection(order)
                                        }
                                      />
                                    }
                                    <p>{idx + 1}</p>
                                  </div>
                                </td>

                                <td className="py-4 text-xs sm:pl-0 whitespace-nowrap text-gray-500">
                                  <div className="flex flex-col items-start space-y-1 max-w-[200px]">
                                    {order?.seller_order_items.some(
                                      (item: any) =>
                                        (item.lineItemKey &&
                                          item.lineItemKey.includes(
                                            'faulty'
                                          )) ||
                                        (item.lineItemKey &&
                                          item.lineItemKey.includes('other'))
                                    ) && (
                                      <WarningText warning="Yeniden Gönderim" />
                                    )}
                                    {(order?.seller_order_items[0]?.label ===
                                      'waitingMatch' ||
                                      order?.seller_order_items[0]?.label ===
                                        'waitingPayment' ||
                                      order?.seller_order_items[0]?.label ===
                                        'waitingProducing' ||
                                      order?.seller_order_items[0]?.label ===
                                        'producing') && (
                                      <WarningText
                                        warning={calculateDate(
                                          order.shipByDate
                                        )}
                                      />
                                    )}
                                    <p>
                                      <span className="text-headerPrimary">
                                        Tarih:
                                      </span>{' '}
                                      {formatDateTR(order.orderDate)}
                                    </p>

                                    <p>
                                      <span className="text-headerPrimary">
                                        Sipariş no:{' '}
                                      </span>
                                      {
                                        order?.seller_order_items[0]
                                          ?.seller_order?.platformOrderId
                                      }
                                    </p>
                                    <p>
                                      <span className="text-headerPrimary">
                                        Son Tarih:
                                      </span>{' '}
                                      {formatDateTR(order.shipByDate)}
                                    </p>
                                    <p className="font-bold text-rexRed">
                                      <span className="text-headerPrimary">
                                        Sipariş adedi:
                                      </span>{' '}
                                      {order.seller_order_items?.length
                                        ? order.seller_order_items.reduce(
                                            (curr: number, item: any) => {
                                              return curr + item.quantity;
                                            },
                                            0
                                          )
                                        : 0}
                                    </p>
                                    <p>
                                      <span className="text-headerPrimary">
                                        Müşteri:{' '}
                                      </span>
                                      {
                                        order?.seller_order_items[0]
                                          ?.seller_order?.fullName
                                      }
                                    </p>
                                    <div className="flex items-center space-x-1">
                                      <p className="text-headerPrimary">
                                        Ülke:{' '}
                                      </p>
                                      <p>
                                        {
                                          order?.seller_order_items[0]
                                            ?.seller_order?.seller_etsy_orders
                                            .customerCountry
                                        }
                                      </p>
                                      <Tooltip
                                        anchorSelect={`#address${idx}`}
                                        content={
                                          order?.seller_order_items[0]
                                            ?.seller_order?.address
                                        }
                                        style={{
                                          width: '200px',
                                        }}
                                      />
                                      <ReactCountryFlag
                                        id={`address${idx}`}
                                        className="ml-1 text-base"
                                        svg
                                        countryCode={
                                          order?.seller_order_items[0]
                                            ?.seller_order?.seller_etsy_orders
                                            .customerCountry
                                        }
                                      />
                                    </div>
                                    <button
                                      className={
                                        'text-white bg-[#1D4ED8] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full max-w-[200px] flex items-center justify-center space-x-2 cursor-pointer'
                                      }
                                      // disabled={selectedOrders.length === 0}
                                      onClick={() => {
                                        toggleOrderSelection(order);
                                        setIsModalOpen(true);
                                      }}
                                    >
                                      <p>Durum Değiştir</p>
                                    </button>
                                    <Link
                                      id="message"
                                      target="_blank"
                                      to={`/messages?to=${order?.seller_order_items[0]?.seller_order.userId}&name=${order?.seller_order_items[0]?.seller_order.user.firstName} ${order?.seller_order_items[0]?.seller_order.user.lastName}`}
                                      className="w-full max-w-[200px] mb-2 block text-center text-xs text-[#1D4ED8] border-2 px-1 py-1 border-[#1D4ED8] rounded-full"
                                    >
                                      Mesaj Gönder
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className={classNames(
                                      order?.seller_order_items.length > 1
                                        ? 'space-y-2'
                                        : 'space-y-0',
                                      ' flex flex-col items-center justify-center z-0 w-[272px]'
                                    )}
                                  >
                                    {order?.seller_order_items.map(
                                      (item: any) => {
                                        return (
                                          <div className="relative z-0">
                                            {item.seller_product.title ===
                                              'Gift Wrap' ||
                                            item.seller_product.title === '' ? (
                                              <img
                                                className="h-[200px] w-[200px] aspect-square object-cover object-center rounded-lg"
                                                src={'/images/giftwrap1.webp'}
                                                alt="Product"
                                              />
                                            ) : (
                                              <img
                                                onLoad={() => {}}
                                                className="h-[200px] w-[200px] aspect-square object-cover object-center rounded-lg z-0"
                                                src={
                                                  item.seller_product.images[0]
                                                    ? item.seller_product
                                                        .images[0]
                                                    : '/images/image-not-found.png'
                                                }
                                                alt="Product"
                                              />
                                            )}
                                            {item.seller_product.title !==
                                              'Gift Wrap' && (
                                              <button
                                                onClick={() => {
                                                  setSelectedImage(
                                                    item.seller_product
                                                      .images[0]
                                                  );
                                                  setIsImageModalOpen(true);
                                                }}
                                                className="opacity-0 w-full h-full absolute top-0 right-0 hover:opacity-100 hover:backdrop-blur-sm"
                                              >
                                                <p className="font-bold text-white">
                                                  Resmi İncele
                                                </p>
                                              </button>
                                            )}

                                            <Tooltip
                                              anchorSelect={`#${item?.id}-matched`}
                                              content="Eşleşen ürün bilgisi"
                                            />

                                            <button
                                              id={`${item?.id}-matched`}
                                              className="bg-[#4F46E5] rounded-lg w-10 h-10 absolute top-2 right-2"
                                              onClick={() => {
                                                setSelectedOrderItem(item.id);
                                              }}
                                            >
                                              <Link2
                                                color="white"
                                                variant="Bold"
                                                className="mx-auto"
                                              />
                                            </button>
                                            <MatchedProductModal
                                              setSelectedOrderItem={
                                                setSelectedOrderItem
                                              }
                                              title={
                                                item.seller_product
                                                  .supplierProductVariantName
                                              }
                                              imgUrl={
                                                item.seller_product
                                                  .supplierProductVariantImages[0]
                                              }
                                              sku={
                                                item.seller_product
                                                  .supplierProductVariantSku
                                              }
                                              index={item.id}
                                              selectedIndex={selectedOrderItem}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </td>
                                <td className="py-4 text-xs md:text-sm text-left overflow-auto text-gray-500 px-3">
                                  <div className="flex flex-col items-start space-y-4 max-w-[220px]">
                                    {order?.seller_order_items.map(
                                      (item: any, index: any) => (
                                        <div
                                          key={index}
                                          className="flex items-center space-x-4 px-2 pb-2 "
                                        >
                                          {/* <input
                                            type="checkbox"
                                            className="rounded-sm border-blue-500"
                                            checked={selectedOrders?.some(
                                              (orderItem: any) =>
                                                orderItem.id === item.id
                                            )}
                                            onChange={() =>
                                              toggleOrderSelection(item)
                                            }
                                          /> */}

                                          <div className="flex flex-col space-y-2">
                                            <p className="text-xs text-left text-[#1F2937] overflow-auto h-16 min-w-[200px] font-medium">
                                              {item.seller_product.title.substring(
                                                0,
                                                70
                                              )}
                                              ...
                                            </p>
                                            <p className="text-xs text-left text-[#1F2937] font-medium">
                                              Fiyat: $
                                              {Number(
                                                item.seller_product
                                                  .supplierProductVariantPrice
                                              ) * item.quantity}
                                            </p>
                                            <p className="font-bold">
                                              <span className="text-headerPrimary">
                                                {'Adet: '}
                                              </span>
                                              <span className="text-rexRed">
                                                {item.quantity}
                                              </span>
                                            </p>

                                            <div className="text-xs text-left text-gray-500 min-w-[200px]">
                                              {(!item.customizationData ||
                                                Object.keys(
                                                  item.customizationData
                                                ).length < 1) &&
                                                Object.entries(
                                                  item.seller_product
                                                    .attributes[0]
                                                ).map(([key, value]) => (
                                                  <div
                                                    className="flex space-x-1"
                                                    key={`${index}-${key}`}
                                                  >
                                                    <p className="text-[#1F2937] font-medium">
                                                      {key}:
                                                    </p>
                                                    <p>{value as string}</p>
                                                  </div>
                                                ))}
                                              <p className="font-medium text-[#1F2937]">
                                                Kişiselleştirme:{' '}
                                                <span className="font-normal">
                                                  {item.personalization
                                                    ? item.personalization
                                                    : item.customizationData
                                                        .length > 0
                                                    ? item.customizationData
                                                        .slice(9)
                                                        .join(' ')
                                                        .replaceAll(
                                                          '- Ek Maliyet: 0\n',
                                                          ''
                                                        )
                                                    : '-'}
                                                </span>
                                              </p>
                                              <div className="flex space-x-1">
                                                <span className="text-[#1F2937]">
                                                  Hediye mi?:
                                                </span>{' '}
                                                <span>
                                                  {item.seller_order
                                                    .seller_etsy_orders.isGift
                                                    ? 'Evet'
                                                    : 'Hayır'}
                                                </span>
                                                <Tooltip
                                                  anchorSelect={`#isGift${idx}`}
                                                  content={
                                                    `Mesaj:${
                                                      item?.seller_order
                                                        ?.seller_etsy_orders
                                                        .giftMessage
                                                        ? item?.seller_order
                                                            ?.seller_etsy_orders
                                                            .giftMessage
                                                        : '-'
                                                    }` +
                                                    '/ ' +
                                                    `Alıcı Mesajı:${
                                                      item?.seller_order
                                                        ?.seller_etsy_orders
                                                        .buyerMessage
                                                        ? item?.seller_order
                                                            ?.seller_etsy_orders
                                                            .buyerMessage
                                                        : '-'
                                                    }`
                                                  }
                                                />
                                                <span
                                                  className="ml-1"
                                                  id={`isGift${idx}`}
                                                >
                                                  <InfoCircle
                                                    size={16}
                                                    color="#111827"
                                                  />
                                                </span>
                                              </div>
                                              {item.seller_order
                                                .seller_etsy_orders.isGift && (
                                                <div className="overflow-x-auto flex space-x-4 max-w-[350px]">
                                                  <span className="text-[#1F2937] whitespace-nowrap">
                                                    Hediye m.:
                                                  </span>{' '}
                                                  <span className=" whitespace-nowrap">
                                                    {item?.seller_order
                                                      ?.seller_etsy_orders
                                                      .giftMessage || '-'}
                                                  </span>
                                                </div>
                                              )}
                                              {item.seller_order
                                                .seller_etsy_orders.isGift && (
                                                <div className="overflow-x-scroll flex space-x-1 max-w-[350px]">
                                                  <span className="text-[#1F2937] whitespace-nowrap">
                                                    Alıcı m.:
                                                  </span>{' '}
                                                  <span className=" whitespace-nowrap">
                                                    {item?.seller_order
                                                      ?.seller_etsy_orders
                                                      .buyerMessage || '-'}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </td>

                                {/* <td className="py-4 text-left text-xs md:text-xs text-gray-500">
                                  <div className="flex flex-col items-start space-y-1">
                                    <div className="text-md font-bold whitespace-nowrap">
                                      <span className="text-[#1F2937] text-lg">
                                        ${' '}
                                        {Number(
                                          order.seller_order_items.reduce(
                                            (acc: any, item: any) =>
                                              acc +
                                              Number(
                                                item.seller_product
                                                  .supplierProductVariantPrice
                                              ) *
                                                item.quantity,
                                            0
                                          )
                                        ).toFixed(2)}
                                      </span>
                                    </div>
                                  </div>
                                </td> */}

                                <td className="py-4 px-2 text-xs text-left  text-gray-500">
                                  {order?.seller_order_items[0].seller_order
                                    .labelPdf ? (
                                    <div className="flex justify-center w-40">
                                      <button
                                        className="flex justify-center items-center space-x-1 rounded-full py-1 px-2 w-28 bg-[#E3211E] text-white"
                                        onClick={() =>
                                          downloadLabel(
                                            order?.seller_order_items[0]
                                              .seller_order.labelPdf
                                          )
                                        }
                                      >
                                        <Ticket size="24" color="white" />
                                        <p>Etiket İndir</p>
                                      </button>
                                    </div>
                                  ) : (
                                    <p className="w-full text-center">
                                      Kargo etiketi henüz oluşturulmamış
                                    </p>
                                  )}
                                  {order.trackingNumber && (
                                    <div className="rounded-lg px-2 w-44">
                                      <button
                                        className="text-xs mt-2 border-gray-500 border-2 rounded-lg px-2 py-1 text-center mb-2 flex cursor-pointer focus:bg-gray-500 focus:text-white"
                                        onClick={() =>
                                          copyToClipboard(order?.trackingNumber)
                                        }
                                      >
                                        {order?.trackingNumber.substring(0, 16)}
                                        ...
                                        <Copy
                                          size={18}
                                          color="#374151"
                                          className="ml-1"
                                        />
                                      </button>
                                      <a
                                        className="flex space-x-2 cursor-pointer border-2 border-[#EA580C] rounded-lg px-2 py-1 text-white mx-auto items-center"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={decideTracking(
                                          order?.serviceCode,
                                          order?.trackingNumber
                                        )}
                                      >
                                        <Truck size={24} color="#EA580C" />
                                        <p className="text-[#EA580C] text-xs">
                                          Kargo Takip
                                        </p>
                                      </a>
                                    </div>
                                  )}
                                </td>

                                <td>
                                  {
                                    <div className="w-72 flex flex-col items-center">
                                      {order?.seller_order_items?.map(
                                        (item: any) => {
                                          return (
                                            <div className="flex">
                                              {renderImages(item)}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  }
                                </td>
                                <td className="py-4 px-4 h-full">
                                  {
                                    <div className="flex flex-col justify-start space-y-2 h-full">
                                      {order?.seller_order_items?.map(
                                        (item: any) => {
                                          return (
                                            <p
                                              className={classNames(
                                                item.note
                                                  ? 'bg-white'
                                                  : 'bg-transparent',
                                                'border-none rounded-lg focus:outline-none h-[200px] overflow-y-scroll mb-2 text-xs min-w-[150px] p-2'
                                              )}
                                            >
                                              {item.note ? item.note : ''}
                                            </p>
                                          );
                                        }
                                      )}
                                    </div>
                                  }
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <p>Sipariş bulunamadı</p>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {selectedImage && (
          <ImageDetailModal
            isModalOpen={isImageModalOpen}
            setIsModalOpen={setIsImageModalOpen}
            img={selectedImage}
          />
        )}
      </div>
    );
  }
);

export default SupplierOrdersTable;
